body {
    margin: 0px 0 0 0;
    height: 100vh;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.87);
    background-color: #e7e1ee;
    padding: 0;
}
* {
    box-sizing: border-box;
}
html {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.page {
    padding:90px 16px 0;
}

.Toastify__toast-container {
    z-index: 11111;
}